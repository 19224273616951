import React, { useEffect, useState } from 'react'

import { IGatsbyImageData } from 'gatsby-plugin-image'
import tw from 'twin.macro'

import { NoFilterMatch } from 'mx/components'
import { WebsiteButton as Button } from 'mx/components/section-components'

import GalleryItemCard from './gallery-item-card'

type GalleryItemsProps = {
  onChangeFilter: (key: string, value: string | null) => void
  itemFromHash: GalleryItem | null
  items: GalleryItem[]
  handleItemClick: (
    item: GalleryItem,
    setCurrentItem: React.Dispatch<React.SetStateAction<GalleryItem | null>>,
  ) => () => void
  setCurrentItem: React.Dispatch<React.SetStateAction<GalleryItem | null>>
}

export type GalleryItem = {
  featuresLevel?: string
  fronts?: {
    color: {
      title: string
      image: { file: { url: string } }
    }
    description: string
    optionKey: string
    texture: string
  }[]
  frontsData?: {
    colors: string[]
    textures: string[]
  }
  handles?: {
    description: string
    optionKey: string
  }[]
  id?: string
  type?: {
    title: string
    imageMain: { file: { url: string } }
  }[]
  layout?: {
    title: string
    image: { file: { url: string } }
  }
  location?: string
  name?: string
  photoGallery: {
    title?: string
    localFile?: {
      childImageSharp?: {
        gatsbyImageData?: IGatsbyImageData
      }
    }
  }[]
  priceRange?: string
  size?: string
  year?: string
}

const GalleryItems = ({
  items,
  handleItemClick,
  onChangeFilter,
  setCurrentItem,
}: GalleryItemsProps) => {
  const itemsPerPage = 12
  const lastPage = (items?.length || 0) / itemsPerPage
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (items?.length > 0) {
      setPage(1)

      const galleryContainer = document.getElementById('galleryContainer')
      if (galleryContainer) galleryContainer.scrollTop = 0
    }
  }, [items])

  const loadMore = () => {
    setPage(page + 1)
  }

  return (
    <div
      id="galleryContainer"
      tw="w-full max-w-full h-full max-h-full pb-12 w-full px-4 sm:(pt-0 mt-12) md:(px-4 pt-4 mt-0 overflow-y-auto)"
    >
      {items?.length > 0 && (
        <>
          <div
            tw="grid grid-cols-1 gap-4 w-full 2xl:(grid-cols-2) h-[max-content]"
            css={[page >= lastPage && tw`mb-32`]}
          >
            {items
              ?.slice(0, itemsPerPage * page)
              .map((item, i) => (
                <GalleryItemCard
                  key={`galleryItem-${i}`}
                  handleDetailsClick={handleItemClick(item, setCurrentItem)}
                  location={item.location}
                  photoGallery={item.photoGallery}
                  priceRange={`${item?.priceRange} (${item?.year})`}
                  title={item.name ?? ''}
                />
              ))}
          </div>
          {page < lastPage && (
            <div tw="w-full flex place-content-center my-3">
              <Button
                rounded
                color="transparentBlack"
                onClick={() => loadMore()}
              >
                View More
              </Button>
            </div>
          )}
        </>
      )}
      {items?.length === 0 && (
        <NoFilterMatch clearFilters={() => onChangeFilter('all', null)} />
      )}
    </div>
  )
}

export default GalleryItems
