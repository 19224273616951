import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { uniqBy } from 'lodash'

import SEO from 'components/shared/seo'
import { useDesignItems } from 'content-queries/gallery/design-items'
import fireEvent from 'context/tracking/events'
import FilterLayout from 'mx/cx/filter-layout'
import GalleryItems, { GalleryItem } from 'mx/cx/gallery/gallery-items'
import ProductModal from 'mx/cx/product-modal'

type Filters = {
  color: string[]
  featuresLevel: string[]
  type: string[]
  layout: string[]
  priceRange: string[]
  size: string[]
  texture: string[]
}

const initialFilters: Record<keyof Filters, any[]> = {
  color: [],
  featuresLevel: [],
  type: [],
  layout: [],
  priceRange: [],
  size: [],
  texture: [],
}

const GalleryPage = ({ path }: { path?: string }) => {
  const projects: GalleryItem[] = useDesignItems()
  const [modalOpen, setModalOpen] = useState(false)

  const content = useMemo(() => {
    const result: GalleryItem[] = []
    projects.forEach((p) => {
      const frontsData: GalleryItem['frontsData'] = { colors: [], textures: [] }
      p.fronts?.forEach((f) => {
        frontsData.colors.push(f.color.title)
        frontsData.textures.push(f.texture)
      })
      result.push({ ...p, frontsData })
    })
    return result
  }, [])

  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Project Inspiration',
    })
  }, [])

  const applyFilters = useCallback(
    (activeFilters: Filters | null) => (item: GalleryItem) => {
      const flags = []
      if (activeFilters?.color)
        flags.push(
          item.frontsData?.colors?.find((c) => activeFilters.color.includes(c)),
        )
      if (activeFilters?.featuresLevel)
        flags.push(
          activeFilters.featuresLevel?.includes(String(item.featuresLevel)),
        )
      if (activeFilters?.type)
        flags.push(item.type?.find((h) => activeFilters.type.includes(h.title)))
      if (activeFilters?.layout)
        flags.push(activeFilters.layout?.includes(String(item.layout?.title)))
      if (activeFilters?.priceRange)
        flags.push(activeFilters.priceRange?.includes(String(item.priceRange)))
      if (activeFilters?.size)
        flags.push(activeFilters.size?.includes(String(item.size)))
      if (activeFilters?.texture)
        flags.push(
          item.frontsData?.textures?.find((t) =>
            activeFilters.texture.includes(t),
          ),
        )

      return flags.every(Boolean)
    },
    [],
  )

  const buildFilterData = useCallback((sortedItems: GalleryItem[]): Filters => {
    const filterData = { ...initialFilters }

    sortedItems.forEach((item) => {
      item.type?.map((type) => {
        const existingHandleType = filterData.type.find(
          (_type) => _type.title === type.title,
        )
        if (!existingHandleType) filterData.type.push(type)
      })
      item.fronts?.forEach((f) => {
        filterData.color.push(f.color)
        filterData.texture.push(f.texture)
      })
      filterData.featuresLevel.push(item.featuresLevel)
      filterData.layout.push(item.layout)
      filterData.priceRange.push(item.priceRange)
      filterData.size.push(item.size)
    })

    return {
      type: filterData.type.sort(),
      color: uniqBy(filterData.color, (c) => c.title),
      featuresLevel: [...new Set(filterData.featuresLevel)]
        .sort()
        .filter(Boolean),
      layout: uniqBy(filterData.layout, (l) => l.title),
      priceRange: [...new Set(filterData.priceRange)].sort().filter(Boolean),
      size: [...new Set(filterData.size)].sort().filter(Boolean),
      texture: [...new Set(filterData.texture)].sort().filter(Boolean),
    }
  }, [])

  const handleOpenModal = useCallback(
    (
      item: GalleryItem,
      setCurrentItem: React.Dispatch<React.SetStateAction<GalleryItem | null>>,
    ) =>
      () => {
        setCurrentItem(item)
        window.location.hash = item.name as string
        setModalOpen(true)
      },
    [],
  )

  const handleCloseModal = useCallback(() => {
    setModalOpen(false)
    const { pathname, search } = window.location
    history.replaceState(undefined, document.title, pathname + search)
  }, [])

  return (
    <FilterLayout<GalleryItem, Filters>
      applyFilters={applyFilters}
      buildFilterData={buildFilterData}
      content={content}
      firstRenderSort={false}
      hideBreadcrumbs={true}
      initialFilters={initialFilters}
      openModal={() => setModalOpen(true)}
      path={path}
      title="Our Projects"
    >
      {({ currentItem, filteredItems, onChangeFilter, setCurrentItem }) => (
        <>
          <GalleryItems
            handleItemClick={handleOpenModal}
            itemFromHash={currentItem}
            items={filteredItems}
            onChangeFilter={onChangeFilter}
            setCurrentItem={setCurrentItem}
          />

          <ProductModal
            description={currentItem?.location}
            handleClose={handleCloseModal}
            info={{
              cabinetryCost: `${currentItem?.priceRange} (${currentItem?.year})`,
              featuresLevel: currentItem?.featuresLevel,
              fronts: currentItem?.fronts?.map((f) => f.description),
              handles: currentItem?.handles?.map((h) => h.description),
              layout: currentItem?.layout?.title,
              size: currentItem?.size,
              type: currentItem?.type?.map((t) => t.title),
            }}
            open={modalOpen}
            photoGallery={currentItem?.photoGallery}
            title={currentItem?.name}
          />
        </>
      )}
    </FilterLayout>
  )
}

export default GalleryPage

export const Head = () => (
  <SEO
    description="Filter our gallery by style, budget, and features to find your perfect kitchen design inspiration"
    title="Project Inspiration"
  />
)
