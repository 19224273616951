import React from 'react'

import 'twin.macro'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Slider, { Settings } from 'react-slick'

import { WebsiteButton as Button } from 'mx/components/section-components'

type PhotoGallery = {
  title?: string
  localFile?: {
    childImageSharp?: {
      gatsbyImageData?: IGatsbyImageData
    }
  }
}[]

const GalleryItemCardSlider = ({ items }: { items: PhotoGallery }) => {
  const settings: Settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    appendDots: (dots: any) => <ul tw="!bottom-4">{dots}</ul>,
  }
  return (
    <div tw="w-full relative xl:(w-11/12 mx-auto) 2xl:(w-full mx-0)">
      <Slider {...settings}>
        {items.map((item, index) => {
          return (
            <div tw="aspect-w-3 aspect-h-2 max-h-72" key={index}>
              <GatsbyImage
                alt="Kitchen"
                image={
                  item.localFile?.childImageSharp
                    ?.gatsbyImageData as IGatsbyImageData
                }
              />
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

type GalleryItemCardPorps = {
  handleDetailsClick: React.MouseEventHandler<HTMLButtonElement>
  location?: string
  photoGallery: PhotoGallery
  priceRange?: string
  title: string
}

const GalleryItemCard = ({
  priceRange,
  handleDetailsClick,
  location,
  photoGallery,
  title,
}: GalleryItemCardPorps) => {
  return (
    <div tw="mb-3">
      <GalleryItemCardSlider items={photoGallery} />
      <div tw="pt-2 flex items-center justify-between xl:(w-11/12 mx-auto) 2xl:(w-full mx-0)">
        <div tw="flex items-center space-x-4">
          <p tw="text-xl font-thin text-gray-800 truncate md:(text-2xl)">
            {title}
          </p>
          {location && (
            <div tw="bg-blue-100 rounded-sm px-2 py-1 w-[max-content]">
              <p tw="text-blue-900 text-xs font-light leading-tight">
                {location.split(',')[1]}
              </p>
            </div>
          )}
          {priceRange && (
            <div tw="bg-orange-100 rounded-sm px-2 py-1 w-[max-content]">
              <p tw="text-amber-900 text-xs font-light leading-tight">
                {priceRange}
              </p>
            </div>
          )}
        </div>
        <Button
          rounded
          onClick={handleDetailsClick}
          color="transparentBlack"
          tw="flex items-center justify-between"
        >
          <span>Details</span>
        </Button>
      </div>
    </div>
  )
}

export default GalleryItemCard
