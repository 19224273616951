import { useStaticQuery, graphql } from 'gatsby'

export const useDesignItems = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulDesign(sort: { fields: [name], order: DESC }) {
        edges {
          node {
            id
            featuresLevel
            fronts {
              color {
                title
                image {
                  file {
                    url
                  }
                }
              }
              description
              optionKey
              texture
            }
            handles {
              description
              optionKey
            }
            handleTypes {
              title
              imageMain {
                file {
                  url
                }
              }
            }
            layout {
              title
              image {
                file {
                  url
                }
              }
            }
            location
            name
            photoGallery {
              title
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    height: 600
                    formats: [WEBP]
                    quality: 100
                  )
                }
              }
            }
            priceRange
            size
            year
          }
        }
      }
    }
  `)

  return content.edges.map((edge: any) => {
    const copy = { ...edge.node }
    delete copy.handleTypes

    copy['type'] = [...edge.node.handleTypes]
    return copy
  })
}
